/*
 * @Author: SongYijie
 * @Date: 2020-05-06 17:54:45
 * @LastEditors: SongYijie
 */
import Vue from 'vue'
import MintUI from 'mint-ui'
import 'mint-ui/lib/style.css'
import '@assets/style/global.css'
import store from './store'
import App from './App.vue'
import router from './router'
import vueSignature from 'vue-signature'
import VueClipboard from 'vue-clipboard2'
// import  VConsole  from  'vconsole';

// let vConsole = new VConsole();
console.log("test");

Vue.config.productionTip = false
Vue.use(MintUI)
Vue.use(vueSignature)
Vue.use(VueClipboard)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
