/*
 * @Author: SongYijie
 * @Date: 2020-05-05 15:05:49
 * @LastEditors: SongYijie
 */

export class CnzzStatistic {
  trackPageView(path) {
    if (!window._czc || !path) {
      return;
    }
    window._czc.push(['_trackPageview', path]);
  }
}

export const cnzz = new CnzzStatistic();
