import Vue from "vue";
import Router from "vue-router";
import Store from "@/store.js";
import { cnzz } from "@utils/cnzz";
Vue.use(Router);
const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "index",
      redirect: "/login",
      component: () => import("@layout/index.vue"),
      children: [
        {
          path: "/taskCenter",
          name: "taskCenter",
          component: () => import("@views/taskCenter/index.vue")
        },
        {
          path: "/myTasks",
          name: "myTasks",
          component: () => import("@views/user/index.vue")
        },
        {
          path: "/individual-business",
          name: "individualBusiness",
          component: () => import("@views/individualBusiness"),
          meta: {
            title: "个体户"
          }
        },
        {
          path: "/aboutUs",
          name: "aboutUs",
          component: () => import("@views/aboutUs/index.vue")
        }
      ]
    },
    {
      path: "/",
      name: "WithChat",
      redirect: "/login",
      component: () => import("@layout/WithChat.vue"),
      children: [
        {
          path: "/login",
          name: "login",
          component: () => import("@views/login/index.vue"),
          meta: {
            keepAlive: true // 需要被缓存
          }
        },
        {
          path: "/user/authentication",
          name: "上传身份证(ocr)",
          component: () => import("@views/user/authentication.vue")
        },
        {
          path: "/user/authentication-check",
          name: "身份证信息确认",
          component: () => import("@views/user/authentication-check.vue")
        },
        {
          path: "/individual-business/base-info",
          name: "个体户基本信息",
          component: () => import("@views/individualBusiness/baseInfo.vue"),
          meta: {
            title: "基本信息"
          }
        },
        {
          path: "/individual-business/confirm-info",
          name: "个体户信息确认",
          component: () => import("@views/individualBusiness/confirmInfo.vue"),
          meta: {
            title: "用户确认"
          }
        },
        {
          path: "/aboutUs/contact",
          name: "联系我们",
          component: () => import("@views/aboutUs/contact.vue")
        }
      ]
    },
    {
      path: "/task/detail/:id",
      props: true,
      name: "任务详情",
      component: () => import("@views/taskCenter/task-detail.vue")
    },
    {
      // 确认签约
      path: "/task/to-sign/:id",
      props: true,
      name: "确认签约",
      component: () => import("@views/taskCenter/task-to-sign.vue")
    },
    {
      // 签约成功
      path: "/task/sign-success",
      props: true,
      name: "正在签约",
      component: () => import("@views/taskCenter/task-sign-success.vue")
    },
    {
      // 合同模板
      path: "/task/protocol-template/:id",
      props: true,
      name: "协议模板",
      component: () => import("@views/taskCenter/protocol-template.vue")
    },
    {
      // 我的签约
      path: "/task/mySigns/:taskId",
      props: true,
      name: "签约列表",
      component: () => import("@views/user/my-sign-list.vue")
    },
    {
      path: "/aboutUs/userInfo",
      name: "用户信息",
      component: () => import("@views/user/user-info.vue")
    },
    {
      path: "/aboutUs/questions",
      name: "常见问题",
      component: () => import("@views/aboutUs/questions.vue")
    },
    {
      path: "/aboutUs/intro",
      name: "关于",
      component: () => import("@views/aboutUs/intro.vue")
    },
    {
      path: "/login/agreement",
      name: "平台服务协议",
      component: () => import("@views/login/agreement.vue")
    },
    {
      path: "/hotTip",
      name: "特别提示",
      component: () => import("@views/login/hot-tip.vue")
    },
    {
      path: "/user/selectArea",
      name: "选择证件类型",
      component: () => import("@views/login/select-area.vue"),
      meta: {
        title: "实名认证"
      }
    },
    {
      path: "/user/description",
      name: "证件说明",
      component: () => import("@views/login/description.vue"),
      meta: {
        title: "证件说明"
      }
    },
    {
      path: "/user/applyUser",
      name: "港澳台用户申请",
      component: () => import("@views/login/apply-user.vue"),
      meta: {
        title: "实名认证"
      }
    },
    {
      path: "/user/confirmApplyUser",
      name: "港澳台用户审核中",
      component: () => import("@views/login/confirm-apply-user.vue"),
      meta: {
        title: "实名认证"
      }
    },
    {
      path: "/user/authentication-success",
      name: "实名认证成功",
      component: () => import("@views/user/authentication-success.vue")
    },
    {
      path: "/user/turnover-list",
      name: "用户流水",
      component: () => import("@views/user/turnover-list.vue")
    },
    {
      path: "/individual-business/face-upload",
      name: "个体户人脸验证",
      component: () => import("@views/individualBusiness/faceUpload.vue"),
      meta: {
        title: "人脸验证"
      }
    },
    {
      path: "/individual-business/license",
      name: "个体工商户营业执照",
      component: () => import("@views/individualBusiness/license.vue"),
      meta: {
        title: "个体工商户营业执照"
      }
    },
    {
      path: "/individual-business/tax-prove",
      name: "税务登记",
      component: () => import("@views/individualBusiness/taxProve.vue"),
      meta: {
        title: "税务登记"
      }
    },
    {
      path: "/individual-business/regist-guide",
      name: "个体户操作指南",
      component: () => import("@views/individualBusiness/registGuide.vue"),
      meta: {
        title: "操作指南"
      }
    },
    {
      path: "/individual-business/protocol",
      name: "个体户合同签署",
      component: () => import("@views/individualBusiness/protocol.vue"),
      meta: {
        title: "合同签署"
      }
    },
    {
      path: "/individual-business/invoice",
      name: "个体户发票",
      component: () => import("@views/individualBusiness/invoice.vue")
    },
    {
      path: "/individual-business/invoice-detail/:id",
      props: true,
      name: "个体户发票详情",
      component: () => import("@views/individualBusiness/invoice-detail.vue"),
      meta: {
        title: "发票详情"
      }
    },
    {
      path: "/individual-business/invoice-apply",
      name: "个体户发票申请",
      component: () => import("@views/individualBusiness/invoiceApply.vue")
    },
    {
      path: "/individual-business/invoice-apply-detail/:id",
      props: true,
      name: "个体户发票申请单详情",
      component: () =>
        import("@views/individualBusiness/invoiceApplyDetail.vue")
    },
    {
      path: "/individual-business/invoice-apply-add",
      name: "个体户新增发票申请单",
      component: () => import("@views/individualBusiness/invoiceApplyAdd.vue")
    },
    {
      path: "/individual-business/invoice-pay-explain/:info",
      props: true,
      name: "个体户发票付款说明",
      component: () => import("@views/individualBusiness/invoicePayExplain.vue")
    },
    {
      path: "/individual-business/invoice-pay-confirm/:info",
      props: true,
      name: "个体户发票支付确认",
      component: () => import("@views/individualBusiness/invoicePayConfirm.vue")
    },
    {
      path: "/individual-business/invoice-apply-income/:urls",
      props: true,
      name: "个体户发票收入凭证",
      component: () =>
        import("@views/individualBusiness/invoiceApplyIncome.vue")
    },
    {
      path: "/individual-business/invoice-apply-invoice/:urls",
      props: true,
      name: "个体户发票凭证",
      component: () =>
        import("@views/individualBusiness/invoiceApplyInvoice.vue")
    },
    {
      path: "/individual-business/sign",
      name: "个体户签名录入",
      component: () => import("@views/individualBusiness/sign.vue"),
      meta: {
        title: "签名录入"
      }
    },
    {
      path: "/individual-business/tax-password-example",
      name: "个体户电子税务登记示例",
      component: () =>
        import("@views/individualBusiness/taxPasswordExample.vue"),
      meta: {
        title: "示例"
      }
    },
    {
      path: "/individual-business/faq",
      name: "个体户常见问题",
      component: () =>
        import("@views/individualBusiness/faq.vue"),
      meta: {
        title: "个体户常见问题"
      }
    },
    {
      path: "/individual-business/operate-video",
      name: "个体户操作指南",
      component: () =>
        import("@views/individualBusiness/operate-video.vue"),
      meta: {
        title: "个体户操作指南"
      }
    },
    {
      path: "/UploadVideo/index",
      name: "上传视频-特别提示",
      component: () =>
        import("@views/UploadVideo/index.vue"),
      meta: {
        title: "特别提示"
      }
    },
    {
      path: "/UploadVideo/shoot",
      name: "上传视频-录制视频",
      component: () =>
        import("@views/UploadVideo/shootTest.vue"),
      meta: {
        title: "录制视频"
      }
    },
    {
      path: "/UploadVideo/landingPage",
      name: "上传视频-上传完成",
      component: () =>
        import("@views/UploadVideo/landingPage.vue"),
      meta: {
        title: "上传完成"
      }
    },
    {
      path: "/paymentPage",
      name: "上传视频-获取授权信息",
      component: () =>
        import("@views/user/paymentPage.vue"),
      meta: {
        title: "获取授权信息"
      }
    },
    {
      path: "*",
      redirect: "/login"
    }
  ]
});
// 导航守卫
// 参数1 : to 目标路由对象
// 参数2 : from 来源路由对象
// 参数3 : next() 下一步
router.beforeEach((to, from, next) => {
  if (to.matched && to.matched.length > 0) {
    cnzz.trackPageView(to.matched[to.matched.length - 1].path);
  }
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = window.location.origin.indexOf('h5.kylincloud-saas') > -1 ? "麒麟云服" : "僖游客";
  }
  if (to.path === "/login" || to.path === "/login/agreement") {
    next();
  } else {
    let user = JSON.parse(JSON.stringify(Store.getters.getUser));
    if ( window.location.href.indexOf('paymentPage') > -1 ) {
      next()
      return
    }
    user && user.userId ? next() : next("/login");
  }
});
export default router;
