/*
 * @Author: SongYijie
 * @Date: 2019-12-23 09:16:24
 * @LastEditors: SongYijie
 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    wxInfo: null
  },
  mutations: {
    setWxInfo (state, wxInfo) {
      state.wxInfo = { ...wxInfo }
    },
    setUser (state, user) {
      state.user = { ...user }
    },
    logout (state) {
      state.user = null
      state.wxInfo = null
      sessionStorage.clear()
    }
  },
  getters: {
    getUser: (state) => {
      return state.user || JSON.parse(sessionStorage.getItem('user'))
    },
    getWxInfo: (state) => {
      return state.wxInfo || JSON.parse(sessionStorage.getItem('wxInfo'))
    }
  }
})
