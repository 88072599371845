<!--
 * @Author: SongYijie
 * @Date: 2020-04-29 10:39:21
 * @LastEditors: SongYijie
 -->
<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>


<script>

export default {
  components: {},
  data() {
    return {
    };
  },
  computed: {},
  watch: {},
  methods: {
  },
  created() {
  }

}
</script>
<style lang='less' scoped>
</style>
